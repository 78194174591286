import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const Multipiattaforma = () => {
  const { multipiattaforma } = useStaticQuery(graphql`
    {
      multipiattaforma: file(relativePath: { eq: "multipiattaforma.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={`Intranet Multipiattaforma | Liferay e Microsoft 365`}
        description={`Intranet 80.20 è pensata per l'esperienza digitale flessibile di Liferay e la modern collaboration di Microsoft 365. Scopri ora la soluzione multipiattaforma!`}
      />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-8">
                <h1 className="hero__title">
                  Multipiattaforma:
                  <br />
                  <strong>Liferay</strong> e <strong>Microsoft 365</strong>
                </h1>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase multipiattaforma">Multipiattaforma</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase multipiattaforma">Multipiattaforma</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__intro">
          <div className="container-lg">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row align-items-md-end">
                  <div className="page-detail__intro-text">
                    <p>
                      La Intranet realizzata sul <strong>back-office nativo</strong> di entrambe le
                      piattaforme di riferimento del mercato: <strong>Liferay</strong> (Java) o{" "}
                      <strong>Microsoft 365</strong>.
                    </p>
                  </div>
                  <div className="page-detail__img-wrap">
                    <GatsbyImage
                      image={getImage(multipiattaforma)}
                      alt="img multipiattaforma"
                      objectPosition="center center"
                      className="page-detail__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <h2 className="mt-0">Liferay</h2>
                <p>
                  Una piattaforma di <strong>esperienza digitale</strong> flessibile e integrata.
                </p>
                <p>
                  <strong>Leader</strong> tra i software Open Source in ambito{" "}
                  <strong>Digital Experience Platform</strong>.
                </p>
                <p>
                  Liferay rappresenta una soluzione di successo per le esigenze di portale di
                  organizzazioni medie e grandi.
                </p>
                <p>
                  L'ampio range di funzionalità pronte all'uso e l'
                  <strong>architettura flessibile e aperta</strong>, studiata per potersi espandere
                  secondo le necessità del business, rendono Liferay un prodotto in grado di unire{" "}
                  <strong>innovazione</strong> tecnologica e <strong>usabilità</strong> per
                  l'utente.
                </p>
                <h2>Microsoft 365</h2>
                <p>
                  La piattaforma di mercato per la <strong>modern collaboration sul cloud</strong>.
                </p>
                <p>
                  <strong>Leader</strong> tra i sistemi per la comunicazione, la condivisione e la
                  collaborazione.
                </p>
                <p>
                  Microsoft 365 è utilizzato da oltre 260 milioni di utenti attivi nel mondo, in
                  costante crescita.
                </p>
                <p>
                  Una vasta gamma di funzionalità native, spesso già comprese nelle sottoscrizioni
                  aziendali, <strong>armonicamente integrate tra loro</strong> nella soluzione
                  Intranet 80.20.
                </p>
                <p>
                  Un’evoluzione continua delle caratteristiche, attraverso frequenti rilasci
                  nell’ambito di una serrata roadmap.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Multipiattaforma;
